<template>
  <div class="header-container">
    <div class="container">
      <div class="header-content row m-0">
        <!-- social -->
        <div class="header-content-social d-none d-md-flex col">
          <div class="d-flex align-items-center">
            <!-- facebook -->
            <a
              href="https://www.facebook.com/revealingclinic/"
              target="_black"
              class="d-flex align-items-center"
            >
              <i class="fab fa-facebook-square"></i>
            </a>
            <!-- intragram -->
            <a
              href="https://www.instagram.com/revealingclinic/"
              target="_black"
              class="d-flex align-items-center"
            >
              <i class="fab fa-instagram"></i>
            </a>
            <!-- twitter -->
            <!-- <a href="#" class="d-flex align-items-center"
            ><i class="fab fa-twitter-square"></i
          ></a>-->
          </div>
        </div>

        <!-- brand -->
        <div class="header-content-brand text-left text-md-center col">
          <router-link :to="{ name: 'Home' }">
            <img src="@images/logo.png" alt="revealing logo" />
          </router-link>
        </div>

        <!-- contact -->
        <div class="header-content-contact d-none d-md-block col">
          <div class="d-flex align-items-center justify-content-end">
            <i class="fas fa-phone-square"></i>
            <div class="p-2 font-size-sm font-weight-light color-dark2">
              062-936-6228
            </div>
          </div>
        </div>

        <!-- menu -->
        <div class="header-menu d-md-none d-block col text-right">
          <transition name="fade" mode="out-in">
            <i
              v-if="menuFade == true"
              id="bars"
              ref="bars"
              key="1"
              class="fas fa-bars"
              @click="menuFade = false"
            ></i>
            <i
              v-else
              id="times"
              ref="times"
              key="2"
              class="fas fa-times"
              @click="menuFade = true"
            ></i>
          </transition>
        </div>
      </div>

      <!-- Header bar desktop -->
      <!-- <div class="hr"></div> -->
      <div class>
        <div class="header-bar row m-0 desktop d-none d-md-flex">
          <div class="col header-bar-list">
            <router-link
              :to="{ name: 'Home' }"
              :class="{ 'router-link-exact-active': parentRoute == 'home' }"
              >หน้าหลัก</router-link
            >
          </div>
          <div class="dropdown col header-bar-list">
            <router-link
              :to="{
                name: 'ServiceSub',
                params: { subname: 'surgery' }
              }"
              :class="{ 'router-link-exact-active': parentRoute == 'service' }"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              >บริการของเรา</router-link
            >
            <div class="dropdown-content">
              <!-- <router-link
              :to="{
                name: 'ServiceSub',
                params: { subname: 'surgery' }
              }"
              class="dropdown-item"
              href="#"
              >ดูดไขมัน</router-link
            > -->
              <!-- surgery -->
              <router-link
                :to="{
                  name: 'ServiceSub',
                  params: { subname: 'surgery' }
                }"
                class="dropdown-item"
                href="#"
                >ศัยกรรมความงาม (ดูดไขมัน)</router-link
              >
              <!-- fat-lifting -->
              <router-link
                :to="{
                  name: 'ServiceSub',
                  params: { subname: 'fat' }
                }"
                class="dropdown-item font-weight-bold"
                href="#"
                >สลายไขมัน และ ยกกระชับ</router-link
              >
              <!-- wrinke -->
              <router-link
                :to="{
                  name: 'ServiceSub',
                  params: { subname: 'wrinkle' }
                }"
                class="dropdown-item"
                href="#"
                >ริ้วรอย ร่องลึก</router-link
              >
              <!-- skin -->
              <router-link
                :to="{
                  name: 'ServiceSub',
                  params: { subname: 'skin' }
                }"
                class="dropdown-item"
                href="#"
                >บำรุงผิว และ เวชศาสตร์ชะลอวัย</router-link
              >
              <!-- scar -->
              <router-link
                :to="{
                  name: 'ServiceSub',
                  params: { subname: 'scar' }
                }"
                class="dropdown-item"
                href="#"
                >หลุมสิว</router-link
              >
              <!-- technology -->
              <router-link
                :to="{
                  name: 'ServiceSub',
                  params: { subname: 'tech' }
                }"
                class="dropdown-item"
                href="#"
                >Hifu</router-link
              >
            </div>
          </div>
          <div class="col header-bar-list">
            <router-link
              :to="{ name: 'Review' }"
              :class="{ 'router-link-exact-active': parentRoute == 'review' }"
              >รีวิว</router-link
            >
          </div>
          <div class="col header-bar-list">
            <router-link
              :to="{ name: 'Blogs' }"
              :class="{ 'router-link-exact-active': parentRoute == 'blogs' }"
              >เกร็ดความรู้</router-link
            >
          </div>
          <div class="col header-bar-list">
            <router-link
              :to="{ name: 'Contact' }"
              :class="{ 'router-link-exact-active': parentRoute == 'contact' }"
              >ติดต่อเรา</router-link
            >
          </div>
        </div>
      </div>
      <!-- <div class="hr"></div> -->

      <!-- Menu list - mobile -->
      <transition name="menu-fade">
        <div v-show="!menuFade" class="fade-menu">
          <div class="fade-menu-box">
            <div class="fade-menu-item">
              <div @click="menuFade = true">
                <router-link :to="{ name: 'Home' }">หน้าหลัก</router-link>
              </div>
              <div>
                <a
                  id="dropdownService"
                  v-b-toggle.collapse-service
                  href="#"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  บริการของเรา
                  <i class="fas fa-caret-down"></i>
                </a>
                <b-collapse id="collapse-service" class="pt-3">
                  <div
                    class="dropdown-content"
                    aria-labelledby="dropdownMenuButton"
                    @click="menuFade = true"
                  >
                    <!-- liposuction -->
                    <!-- <router-link
                    :to="{
                      name: 'ServiceSub',
                      params: { subname: 'surgery' }
                    }"
                    class="dropdown-item"
                    href="#"
                    >ดูดไขมัน</router-link
                  > -->
                    <!-- surgery -->
                    <router-link
                      :to="{
                        name: 'ServiceSub',
                        params: { subname: 'surgery' }
                      }"
                      class="dropdown-item"
                      href="#"
                      >ศัยกรรมความงาม (ดูดไขมัน)</router-link
                    >
                    <!-- fat-lifting -->
                    <router-link
                      :to="{
                        name: 'ServiceSub',
                        params: { subname: 'fat' }
                      }"
                      class="dropdown-item font-weight-bold"
                      href="#"
                      >สลายไขมัน และ ยกกระชับ</router-link
                    >
                    <!-- wrinke -->
                    <router-link
                      :to="{
                        name: 'ServiceSub',
                        params: { subname: 'wrinkle' }
                      }"
                      class="dropdown-item"
                      href="#"
                      >ริ้วรอย ร่องลึก</router-link
                    >
                    <!-- skin -->
                    <router-link
                      :to="{
                        name: 'ServiceSub',
                        params: { subname: 'skin' }
                      }"
                      class="dropdown-item"
                      href="#"
                      >บำรุงผิว และ เวชศาสตร์ชะลอวัย</router-link
                    >
                    <!-- scar -->
                    <router-link
                      :to="{
                        name: 'ServiceSub',
                        params: { subname: 'scar' }
                      }"
                      class="dropdown-item"
                      href="#"
                      >หลุมสิว</router-link
                    >
                    <!-- technology -->
                    <router-link
                      :to="{
                        name: 'ServiceSub',
                        params: { subname: 'tech' }
                      }"
                      class="dropdown-item"
                      href="#"
                      >Hifu</router-link
                    >
                    <hr class="w-50" />
                  </div>
                </b-collapse>
              </div>
              <div @click="menuFade = true">
                <router-link :to="{ name: 'Review' }" @click="menuFade = true"
                  >รีวิว</router-link
                >
              </div>
              <div @click="menuFade = true">
                <router-link :to="{ name: 'Blogs' }" @click="menuFade = true"
                  >เกร็ดความรู้</router-link
                >
              </div>
              <div @click="menuFade = true">
                <router-link :to="{ name: 'Contact' }" @click="menuFade = true"
                  >ติดต่อเรา</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuFade: true,
      collapseState: {},
      parentRoute: null
    };
  },
  watch: {
    // close collapse when menu toggle
    menuFade: function(val) {
      if (val && this.collapseState["collapse-service"]) {
        this.$root.$emit("bv::toggle::collapse", "collapse-service");
      }
    }
  },
  mounted() {
    // init collapse state
    this.$root.$on("bv::collapse::state", (collapseId, isJustShown) => {
      this.collapseState[collapseId] = isJustShown;
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.parentRoute = to.path.split("/")[1];
    next();
  }
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease-out, transform 0.1s ease-in-out;
}

.fade-leave-active {
  transform: rotate(90deg);
}

.fade-enter {
  transform: rotate(-90deg);
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.menu-fade-enter-active,
.menu-fade-leave-active {
  transition: opacity 0.4s ease-out, transform 0.2s ease-in-out;
}

.menu-fade-enter,
.menu-fade-leave-active {
  opacity: 0;
}

.header-container {
  a {
    transition: color 0.3s ease-out;
  }
  border-bottom: 1px solid #f5f5f5;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.06);
}

.header-bar {
  background: #fff;
}
</style>
